// import { Link } from "gatsby"
import React from "react"
// import PropTypes from "prop-types"
// import classNames from 'classnames'

import kukeFeatures from "../json/kuke-features.json"
import Svg from "./svg"
import { CDNBASE } from "../utils/maps"
import PopVideo from "./PopVideo"

import st from "./css/kukeFeature.module.scss"

class KukeFeature extends React.Component {
  state = {
    preloader: false,
    visible: false,
    videoSrc: "",
  }

  componentDidMount() {
    this.timeOutID = setTimeout(() => {
      this.setState({ preloader: true })
    }, 5000)
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutID)
  }
  hdVideo = video => {
    this.setState({
      visible: true,
      videoSrc: `intro/${video}.mp4`,
    })
  }
  closeVideo = e => {
    console.log(e.target)
    if (e.target.dataset.typ === "close") {
      this.setState({
        visible: false,
        videoSrc: "",
      })
    }
  }

  render() {
    // const { } = this.state;
    const { isMobile } = this.props;
    const iconSize = isMobile ? 80 : 120

    return (
      <div className={st.wrapper}>
        {kukeFeatures.map((v, i) => (
          <div key={v.icon} className={st.feaWrapper}>
            <i id={`intro-${v.icon}`} className="g-link-tag"></i>
            <div className={st.introWrapper}>
              <div style={{marginLeft: isMobile ? -7 : -12}}>
                <div
                  style={{
                    width: iconSize,
                    height: iconSize,
                    display: "inline-block",
                    verticalAlign: "middle",
                    backgroundImage: `url(${CDNBASE}/home//kuke_features/icons/features.png)`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "auto 100%",
                    backgroundPositionY: 0,
                    backgroundPositionX:  -(i * (iconSize - (isMobile ? 0.5 : 1))),
                  }}
                ></div>
                <div style={{ display: "inline-block", verticalAlign: "middle", marginLeft: 20 }}>
                  <h3 className={st.title}>{v.title}</h3>
                  <div className={st.desc}>{v.desc}</div>
                </div>
              </div>
              <div className={st.list}>
                {v.items.map(item => (
                  <div key={item.id} className={st.item}>
                    <span className={st.itemID} style={{ background: v.color }}>
                      {item.id}{" "}
                      <i
                        className={st.arrow}
                        style={{
                          borderColor: `transparent transparent transparent ${v.color}`,
                        }}
                      ></i>
                    </span>
                    <span className={st.itemName}>{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className={st.videoWrapper}>
              <div className={st.bg} style={{ background: v.color }}></div>
              <div
                className={st.cover}
                onClick={this.hdVideo.bind(null, v.icon)}
                style={{
                  backgroundImage: `url(${CDNBASE}/home/kuke_features/covers/${v.icon}.png)`,
                }}
              >
                <span className={st.videoIconWrapper}>
                  <Svg svgClass={st.videoIcon} id="videoPlay" />
                </span>
              </div>
            </div>
          </div>
        ))}

        <PopVideo
          visible={this.state.visible}
          src={this.state.videoSrc}
          onClose={this.closeVideo}
        />
        {this.state.preloader && (
          <div className={st.preloader}>
            {kukeFeatures.map(v => (
              <video key={v.icon} preload="metadata">
                <source
                  src={`https://cdn.kukecloud.com/assets/www/assets/video/intro/${v.icon}.mp4`}
                  type="video/mp4"
                />
              </video>
            ))}
          </div>
        )}
      </div>
    )
  }
}

export default KukeFeature
